import request from './request.js';

export const orderDetail = (orderNumber) => {
  return request({
    url: '/api/blade-admin/order/detail',
    method: 'get',
    params: {
      orderNumber: orderNumber
    }
  })
}

export const orderList = (params) => {
  return request({
    url: '/api/blade-admin/order/page',
    method: 'get',
    params
  })
}

export const orderSubmit = (data) => {
  return request({
    url: '/api/blade-admin/order/submit',
    method: 'post',
    data
  })
}


export const getMineOrderList = (params) => {
  return request({
    url: '/api/blade-weixin/info/myOrder',
    method: 'get',
    params
  })
}


// 选择送货员
export const peopleList = () => {
  return request({
    url: '/api/blade-user/user-list',
    method: 'get',
    params: {
      roleId: '1466402285565784065'
    }
  })
}

//退款
export const orderRefund = (data) => {
  return request({
    url: '/api/blade-weixin/info/refund',
    method: 'post',
    data
  })
}

