<template>
  <div class="warp">
    <div class="top-warp">
      <div class="tip-name">商城统计</div>
      <div class="select-time">
        <van-cell :value="date" @click="show = true" />
        <van-calendar v-model:show="show" type="range" @confirm="onConfirm" :allow-same-day="true" :min-date="minDate" :max-date="maxDate"/>
      </div>
    </div>
    <div class="data-info">
      <div class="first-row">
        <div class="val">{{ countInfos.shoppingOrders }}</div>
        <div>订单数</div>
      </div>
      <div class="first-row">
        <div class="val">{{ countInfos.shoppingSales }}</div>
        <div>销售额</div>
      </div>
      <div class="first-row">
        <div class="val">{{ countInfos.shoppingReceipts }}</div>
        <div>实收额</div>
      </div>
    </div>
    <div>
      <div v-for="item in dataList" :key="item.id" class="order-info">
        <router-link :to="`/order_detail?orderNumber=${item.orderNumber}`">
          <div style="color: #666">
            <div class="name"><span>{{ item.receiver }}</span><span>￥{{ item.actualTotal }}</span></div>
            <div class="time">{{ item.createTime }} 下单</div>
            <div class="infos">
              <span>{{ item.payType == 1 ? '微信支付' : '余额支付' }}</span>
              <span>{{ shopOrderStatus[item.status] }}</span>
              <span v-if="item.deliveryUserName">{{ item.deliveryUserName }}</span>
            </div>
            <div class="time">{{ item.address }}</div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { homeInfos } from '../../assets/api/index'
const dateNow = new Date();
const year = dateNow.getFullYear();
const month = dateNow.getMonth() > 8 ? dateNow.getMonth()+1 : `0${dateNow.getMonth()+1}`;
const day = dateNow.getDate() > 9 ? dateNow.getDate() : `0${dateNow.getDate()}`;
import { orderList } from '../../assets/api/order.js'
import { shopOrderStatus } from '../../util/orderStatus.js';
export default {
  data() {
    return {
      maxDate: new Date(year, month, day),
      minDate: new Date(2021,11,1),
      date: `${year}-${month}-${day} - ${year}-${month}-${day}`,
      startDate: `${year}-${month}-${day}`,
      endDate: `${year}-${month}-${day}`,
      show: false,
      dataList: [],
      shopOrderStatus: shopOrderStatus,
      countInfos: {
        shoppingOrders: 0,
        shoppingSales: 0,
        shoppingReceipts: 0
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth()>8?`${date.getMonth() + 1}`: `0${date.getMonth() + 1}`}-${date.getDate()>9?date.getDate():`0${date.getDate()}`}`;
    },
    onConfirm(val) {
      const [start, end] = val;
      this.show = false;
      this.startDate = this.formatDate(start)
      this.endDate = this.formatDate(end)
      this.date = `${this.startDate} - ${this.endDate}`;
      this.initData()
    },
    initData() {
      homeInfos({startDate: this.startDate, endDate: this.endDate}).then(res => {
        const infos = res.data.data
        this.countInfos.shoppingOrders = infos.shoppingOrders
        this.countInfos.shoppingSales = infos.shoppingSales
        this.countInfos.shoppingReceipts = infos.shoppingReceipts
      })
      const params = {
        createTime: this.startDate +','+ this.endDate,
        orderySource: 1,
        current: 1,
        size: 1000,
      }
      orderList(params).then(res => {
        this.dataList = res.data.data.records
      })
    }
  }
}
</script>

<style scoped lang="less">
::v-deep(.van-card__thumb) {
  width: 60px;
  height: 60px;
}
::v-deep(.van-card__content) {
  height: 60px;
  min-height: 60px;
}
.warp {
  padding: 15px 10px;
  .top-warp {
    width: 100%;
    display: flex;
    height: 44px;
    line-height: 44px;

    .tip-name {
      width: 100px;
      font-weight: 600;
      font-size: 20px;
      margin-right: 12px;
    }

    .select-time {
      width: calc(100% - 112px);
    }
  }
  .data-info {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .first-row {
      height: 80px;
      width: calc(33% - 5px);
      text-align: center;
      border-radius: 8px;
      border: 1px solid #999;

      .val {
        font-size: 18px;
        font-weight: 600;
        line-height: 44px;
      }
    }
  }
  .order-info {
    width: calc(100% - 20px);
    color: #666;
    font-size: 12px;
    border: 1px solid #aaaaaa;
    border-radius: 8px;
    margin-top: 15px;
    padding: 5px 10px;
    position: relative;
    background: #f7f8fabd;

    .name {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
    }
    .name, .time, .infos {
      line-height: 18px;
    }
    .time {
      margin: 4px 0;
    }
    .infos {
      display: flex;
      span {
        margin-right: 10px;
        border: 1px solid #999999;
        border-radius: 6px;
        padding: 0 6px;
      }
    }
    .van-btn {
      min-width: 80px;
      border: none;
      height: 24px;
      line-height: 24px;
      margin-top: 10px;
      border-radius: 5px;
    }
  }
}
</style>
